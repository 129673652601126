<template>
  <div ref="deviceTabContainer" class="pt-6 mb-2">
    <span class="flex">
      <h1 class="font-medium text-left text-gray-900">{{ $t("device.header.bracelets") }}</h1>
      <div class="relative px-2 text-sm font-semibold text-left text-gray-900 group w-fit">
        <InformationCircleIcon class="w-5 text-gray-500 group hover:text-cyan-800" aria-hidden="true" />
        <div class="absolute invisible sm:right-0 right-2.5 w-32 p-3 pl-4 mt-2 origin-top bg-white divide-y divide-gray-100 rounded-md shadow-lg group-hover:visible ring-1 ring-black ring-opacity-5 focus:outline-none">
          <img class="h-24 amplify-image" src="../assets/bracelet_back.png" :alt="$t('device.header.bracelets')" />
        </div>
      </div>
    </span>

    <dd class="mt-2 text-sm text-gray-900">
      <ul class="divide-gray-200">
        <li class="flex items-center justify-between pb-4 pr-4 text-sm" v-for="(bracelet, index) in bracelets" :key="bracelet.$uuid" @keyup="validateBraceletAt(index)" @change="validateBraceletAt(index)">
          <div class="flex items-center flex-1 w-0 gap-2">
            <BraceletIcon class="w-8 h-8 text-gray-400" aria-hidden="true" />
            <input v-model="bracelet.serial_number" type="text" :class="[
              'w-full h-8 border-gray-300 rounded-md shadow-sm font-mono flex-1',
              bracelet.$error !== ''
                ? 'border-red-500 focus:border-red-600 focus:ring-red-500'
                : 'focus:ring-indigo-500 focus:border-indigo-500',
            ]" />

            <button ref="removeButtonRef" class="flex items-center ml-1 text-gray-400" @click="removeBraceletAt(index)">
              <MinusCircleIcon class="w-6 h-6 text-gray-500" aria-hidden="true" />
            </button>
            <button ref="addButtonRef" class="flex items-center ml-1 text-gray-400" v-if="index == bracelets.length - 1 && index < 4" @click="addBracelet()">
              <PlusCircleIcon class="w-6 h-6 text-gray-500" aria-hidden="true" />
            </button>
            <div v-else class="ml-0 w-7"></div>
          </div>
        </li>

        <li v-show="bracelets.length == 0">
          <div class="flex justify-between gap-2">
            <div class="w-full"></div>
            <button ref="addButtonZeroRef" class="flex items-center ml-1 mr-3 text-gray-400" @click="addBracelet()">
              <PlusCircleIcon class="w-6 h-6 text-gray-500" aria-hidden="true" />
            </button>
          </div>
        </li>

      </ul>
      <div class="h-6 pb-3 pl-1 mt-1 mb-2">
        <div class="flex items-center text-sm text-red-500" v-if="bracelets.filter((bracelet) => bracelet.$error !== '').length">
          <ExclamationCircleIcon class="w-5 h-5" aria-hidden="true" />
          <p class="ml-4">{{ bracelets.find((bracelet) => bracelet.$error !== '').$error }}</p>
        </div>
      </div>
    </dd>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, reactive, watch } from "vue";
import { BraceletIcon } from "@/components/icons";
import { InformationCircleIcon, ExclamationCircleIcon } from "@heroicons/vue/outline";
import { useI18n } from "vue-i18n";
import { hasDeviceTenantPermission, USER_PERMISSIONS } from "@/lib/userHasPermission";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/vue/outline";
import { v4 as uuidv4 } from "uuid";
import i18n from "@/i18n";

export default {
  name: "DeviceTabBracelet",
  components: {
    BraceletIcon,
    InformationCircleIcon,
    ExclamationCircleIcon,
    MinusCircleIcon,
    PlusCircleIcon,
  },

  props: ["refresh"],
  emits: ["updateConfig"],

  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const device = computed(() => store.state.device.current);
    const isEditAllowed = computed(() => hasDeviceTenantPermission(USER_PERMISSIONS.ADMIN));

    const isValidBraceletId = (braceletId) => {
      return /^\d+$/.test(braceletId) && braceletId.length === 12;
    };

    const validateBraceletAt = (index) => {

      const { serial_number } = bracelets[index];
      if (!isValidBraceletId(serial_number)) {
        bracelets[index].$error = i18n.global.t("error.braceletFormat");
      } else {
        bracelets[index].$error = "";
      }

      const unique = bracelets.filter((obj) => obj.serial_number === bracelets[index].serial_number)
      if (unique.length > 1) bracelets[index].$error = i18n.global.t("error.braceletDuplicate");

      updateBracelets();

    };

    let bracelets = reactive(
      device.value?.configV2?.accessories?.bracelets?.map((item) => ({
        $uuid: uuidv4(),
        $error: isValidBraceletId(item.serial_number) ? "" : item.$error,
        ...item,
      }))
    );

    const removeBraceletAt = (index) => {
      bracelets.splice(index, 1);
      updateBracelets();
    };

    const addBracelet = () => {
      if (bracelets.length < 5) {
        bracelets.push({ id: "", error: "" });
      }
    };

    const updateBracelets = () => {
      emit(
        "updateConfig",
        bracelets.map((bracelet) => ({ serial_number: bracelet.serial_number, valid: bracelet.$error !== "" ? false : true }))
      );
    };

    watch(() => props.refresh, () => {
      if (props.refresh) {
        reset();

      }
    })

    watch(device, () => {
      if (device.value) {
        reset();
      }
    });

    const reset = () => {
      bracelets.splice(0);
      let storedBracelets = device.value?.configV2?.accessories?.bracelets;
      for (let bracelet of storedBracelets) {
        bracelets.push({
          $uuid: uuidv4(),
          $error: isValidBraceletId(bracelet.serial_number) ? "" : bracelet.$error,
          serial_number: bracelet.serial_number
        });
      }
    }

    return {
      t,
      isEditAllowed,
      removeBraceletAt,
      addBracelet,
      isValidBraceletId,
      validateBraceletAt,
      device,
      bracelets,
    };
  },
};
</script>
