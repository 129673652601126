<template>
  <dl class="flex flex-wrap gap-3 mb-3 md:flex-nowrap ">
    <div class="flex flex-wrap justify-between w-full px-4 py-1 overflow-hidden rounded-lg shadow-md md:py-2 overflow state_unknown" ref="serialNumberContainer">
      <dt class="my-auto text-sm truncate xl:w-fit lg:w-full">{{ $t("device.header.serialNumber") }}</dt>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-if="device">{{ device.serialNumber }} </dd>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-else>-</dd>
    </div>

    <div class="flex flex-wrap justify-between w-full px-4 py-1 overflow-hidden rounded-lg shadow-md md:py-2 overflow state_unknown">
      <dt class="my-auto text-sm truncate xl:w-fit lg:w-full">{{ $t("device.header.id") }}</dt>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base">{{ getDeviceId(device) ? getDeviceId(device) : "-" }} </dd>
    </div>

    <div class="flex flex-wrap justify-between w-full px-4 py-1 overflow-hidden rounded-lg shadow-md md:py-2 overflow state_unknown" ref="firmwareVersionContainer">
      <dt class="my-auto text-sm truncate xl:w-fit lg:w-full">{{ $t("device.header.firmware") }} </dt>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-if="device.firmware && device.firmware.version">{{ device.firmware.version }} </dd>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-else>-</dd>
    </div>

    <div class="flex flex-wrap justify-between w-full px-4 py-1 overflow-hidden rounded-lg shadow-md md:py-2 overflow state_unknown">
      <dt class="my-auto mr-2 text-sm xl:w-fit lg:w-full">{{ $t("device.header.tenant") }} </dt>
      <dd class="my-auto text-sm font-medium truncate lg:font-semibold lg:text-base" v-if="device.owner && device.owner.name">{{ device.owner.name }} </dd>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-else>-</dd>
    </div>
  </dl>

  <div class="relative ">
    <dl class="relative top-0 flex flex-wrap justify-between w-full gap-3 lg:flex-nowrap">

      <!-- network -->
      <AlarmTooltip :targetId="'tooltipTriggerHighestNetwork'" type="network" :alert="getHighestAlert('network', device)" :device="device" />

      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto h-full cursor-pointer w-full 2xl:flex-auto 2xl:w-full order-1 2xl:order-none fullHeightNetwork',
        ...stateColor(device, 'network')
      ]" ref="networkStatusContainer">
        <div data-accordion="open">
          <div class="flex flex-wrap justify-between text-gray-800 bg-transparent" id="accordion-open-heading-network" data-accordion-target="#accordion-open-body-network" aria-expanded="false" aria-controls="accordion-open-body-network">
            <div class="flex justify-between w-full">
              <div class="justify-between block">
                <dt class="text-sm truncate lg:text-xs 2xl:text-sm">{{ $t("device.header.connectivityStatus") }}</dt>

                <div v-if="hasNetworkAlert" class="text-sm font-medium lg:font-semibold" ref="networkStatusHasAlert">
                  <p id='tooltipTriggerHighestNetwork' class="flex" v-html="getMostCriticalAlert('network', device)"></p>
                </div>

                <div v-else class="pr-5 mr-5 text-sm font-medium lg:font-semibold" ref="networkStatusNoAlert">
                  <span v-if="getOnlineStatus(device) == 'Online'" class="truncate">
                    Online via 4G/LTE {{ getConnectionStatus(device) }}
                  </span>
                  <span v-else>{{ powerSupplyStatusText(device, $t) }}</span>
                </div>

              </div>
              <div class="flex items-center flex-shrink-0 lg:hidden 2xl:flex">
                <BellIcon v-if="hasNetworkAlert" class="w-8 h-8 px-1 mx-1 rounded-full stroke-red-800" aria-hidden="true" />
                <DeviceMobileIcon v-else class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
              </div>

            </div>
            <div class="flex justify-center flex-1 w-full mt-0 text-gray-400 rotate-180 2xl:mt-2">
              <svg data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </div>
          </div>

          <div id="accordion-open-body-network" class="hidden pb-0" aria-labelledby="accordion-open-heading-network">
            <p id='tooltipTriggerModemLink' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('network', 'modem_link', device)"></span>
              <span class='flex items-center text-sm font-light hover:underline hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.modem_link") }}</span>
            </p>
            <AlarmTooltip :targetId="'tooltipTriggerModemLink'" type="network" alert="modem_link" :device="device" />

            <p id='tooltipTriggerModemConQuality' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('network', 'modem_connection_quality', device)"></span>
              <span class='flex items-center text-sm font-light hover:underline hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.modem_connection_quality") }}</span>
            </p>
            <AlarmTooltip :targetId="'tooltipTriggerModemConQuality'" type="network" alert="modem_connection_quality" :device="device" />

            <p id='tooltipTriggerNetCloudRep' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('network', 'network_cloud_reported', device)"></span>
              <span class='flex items-center text-sm font-light hover:underline hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.network_cloud_reported") }}</span>
            </p>
            <AlarmTooltip :targetId="'tooltipTriggerNetCloudRep'" type="network" alert="network_cloud_reported" :device="device" />

            <p v-if="hasArcConfigured" id='tooltipTriggerNetArcRep' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('network', 'network_arc_reported', device)"></span>
              <span class='flex items-center text-sm font-light hover:underline hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.network_arc_reported") }}</span>
            </p>
            <AlarmTooltip v-if="hasArcConfigured" :targetId="'tooltipTriggerNetArcRep'" type="network" alert="network_arc_reported" :device="device" />

          </div>
        </div>
      </div>


      <!-- WIFI -->
      <AlarmTooltip :targetId="'tooltipTriggerHighestWifi'" type="wifi" :alert="getHighestAlert('wifi', device)" :device="device" />

      <div v-show="isConnectedWifi" :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto h-full cursor-pointer w-full 2xl:flex-auto 2xl:w-full order-1 2xl:order-none fullHeightWifi',
        ...stateColor(device, 'wifi')]" ref="wifiStatusContainer">

        <div data-accordion="open">
          <div class="flex flex-wrap justify-between text-gray-800 bg-transparent" id="accordion-open-heading-wifi" data-accordion-target="#accordion-open-body-wifi" aria-expanded="false" aria-controls="accordion-open-body-wifi">
            <div class="flex justify-between w-full">
              <div class="justify-between block">
                <dt class="text-sm truncate lg:text-xs 2xl:text-sm">{{ $t("stateSummary.label.wifiConnection") }}</dt>

                <div v-if="hasWifiAlert" class="text-sm font-medium lg:font-semibold">
                  <p id='tooltipTriggerHighestWifi' class="flex" v-html="getMostCriticalAlert('wifi', device)"></p>
                </div>
                <div v-else class="text-sm font-medium lg:font-semibold">
                  {{ wifiStatusText(device, $t) }}
                </div>
              </div>
              <div class="flex items-center flex-shrink-0 lg:hidden 2xl:flex">
                <BellIcon v-if="hasWifiAlert" class="w-8 h-8 px-1 mx-1 rounded-full stroke-red-800" aria-hidden="true" />
                <WifiIcon v-else class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
              </div>

            </div>
            <div class="flex justify-center flex-1 w-full mt-0 text-gray-400 rotate-180 2xl:mt-2">
              <svg data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </div>
          </div>

          <div id="accordion-open-body-wifi" class="hidden pb-0" aria-labelledby="accordion-open-heading-wifi">
            <p><br v-if="hasArcConfigured"><br></p>
            <p id='tooltipTriggerWifiLink' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('wifi', 'wifi_link', device)"></span>
              <span class='flex items-center text-sm font-light hover:underline hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.wifi_link") }}</span>
            </p>
            <AlarmTooltip :targetId="'tooltipTriggerWifiLink'" type="wifi" alert="wifi_link" :device="device" />

            <p id='tooltipTriggerWifiConnectionQuality' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('wifi', 'wifi_connection_quality', device)"></span>
              <span class='flex items-center text-sm font-light hover:underline hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.wifi_connection_quality") }}</span>
            </p>
            <AlarmTooltip :targetId="'tooltipTriggerWifiConnectionQuality'" type="wifi" alert="wifi_connection_quality" :device="device" />

          </div>
        </div>
      </div>

      <!-- Power status -->
      <AlarmTooltip :targetId="'tooltipTriggerHighestPower'" type="power" :alert="getHighestAlert('power', device)" :device="device" />

      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto h-full cursor-pointer w-full 2xl:flex-auto 2xl:w-full order-1 2xl:order-none fullHeightPower',
        ...stateColor(device, 'power')]">
        <div data-accordion="open">

          <div class="flex flex-wrap justify-between text-gray-800 bg-transparent " id="accordion-open-heading-power" data-accordion-target="#accordion-open-body-power" aria-expanded="false" aria-controls="accordion-open-body-power">
            <div class="flex justify-between w-full">
              <div class="justify-between block">
                <dt class="text-sm truncate lg:text-xs 2xl:text-sm">{{ $t("stateSummary.label.powerSupplyState") }}</dt>

                <div v-if="hasPowerAlert" class="text-sm font-medium lg:font-semibold ">
                  <p id='tooltipTriggerHighestPower' class="flex" v-html="getMostCriticalAlert('power', device)"></p>
                </div>
                <div v-else class="text-sm font-medium lg:font-semibold ">
                  {{ powerSupplyStatusText(device, $t) }}
                </div>
              </div>

              <div class="flex items-center flex-shrink-0 lg:hidden 2xl:flex">
                <BellIcon v-if="hasPowerAlert" class="w-8 h-8 px-1 mx-1 rounded-full stroke-red-800" aria-hidden="true" />
                <BatteryIcon v-else class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
              </div>
            </div>
            <div class="flex justify-center flex-1 w-full mt-0 text-gray-400 rotate-180 2xl:mt-2">
              <svg data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </div>
          </div>
          <div id="accordion-open-body-power" class="hidden pb-0" aria-labelledby="accordion-open-heading-power">
            <p><br v-if="hasArcConfigured"><br></p>
            <p id='tooltipTriggerPowerSupply' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('power', 'power_supply', device)"></span>
              <span class='flex items-center text-sm font-light hover:underline hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.power_supply") }}</span>
            </p>
            <AlarmTooltip :targetId="'tooltipTriggerPowerSupply'" type="power" alert="power_supply" :device="device" />

            <p id='tooltipTriggerBatteryFault' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('power', 'battery_fault', device)"></span>
              <span class='flex items-center text-sm font-light hover:underline hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.battery_fault") }}</span>
            </p>
            <AlarmTooltip :targetId="'tooltipTriggerBatteryFault'" type="power" alert="battery_fault" :device="device" />

          </div>
        </div>
      </div>

      <!-- Accessory status -->
      <AlarmTooltip :targetId="'tooltipTriggerHighestAccessories'" type="accessories" :alert="getHighestAlert('accessories', device)" :device="device" />

      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto h-full cursor-pointer w-full 2xl:flex-auto 2xl:w-full order-1 2xl:order-none fullHeightAccess',
        ...stateColor(device, 'accessories')
      ]">
        <div data-accordion="open">
          <div class="flex flex-wrap justify-between text-gray-800 bg-transparent" id="accordion-open-heading-accessory" data-accordion-target="#accordion-open-body-accessory" aria-expanded="false" aria-controls="accordion-open-body-accessory">
            <div class="flex justify-between w-full">
              <div class="justify-between block">
                <dt class="text-sm truncate lg:text-xs 2xl:text-sm">{{ $t("stateSummary.label.braceletPresenceState") }}</dt>

                <div v-if="hasAccessoryAlert" class="text-sm font-medium lg:font-semibold ">
                  <p id="tooltipTriggerHighestAccessories" class="flex" v-html="getMostCriticalAlert('accessories', device)"></p>
                </div>
                <div v-else class="text-sm font-medium lg:font-semibold"> {{ braceletPresenceStatusText(device, $t) }} </div>
              </div>

              <div class="flex items-center flex-shrink-0 lg:hidden 2xl:flex">
                <BellIcon v-if="hasAccessoryAlert" class="w-8 h-8 px-1 mx-1 rounded-full stroke-red-800" aria-hidden="true" />
                <BraceletIcon v-else class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
              </div>
            </div>
            <div class="flex justify-center flex-1 w-full mt-0 text-gray-400 rotate-180 2xl:mt-2">
              <svg data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </div>
          </div>
          <div id="accordion-open-body-accessory" class="hidden pb-0" aria-labelledby="accordion-open-heading-accessory">
            <p><br v-if="hasArcConfigured"><br></p>
            <p id='tooltipTriggerBraceletPresence' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('accessories', 'bracelet_presence', device)"></span>
              <span class='flex items-center text-sm font-light hover:underline hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.bracelet_presence") }}</span>
            </p>
            <AlarmTooltip :targetId="'tooltipTriggerBraceletPresence'" type="accessories" alert="bracelet_presence" :device="device" />

            <p id='tooltipTriggerBraceletBattery' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('accessories', 'bracelet_battery', device)"></span>
              <span class='flex items-center text-sm font-light hover:underline hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.bracelet_battery") }}</span>
            </p>
            <AlarmTooltip :targetId="'tooltipTriggerBraceletBattery'" type="accessories" alert="bracelet_battery" :device="device" />

          </div>
        </div>
      </div>


      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto justify-between relative h-full cursor-pointer w-full 2xl:flex-auto 2xl:w-full order-1 2xl:order-none',
        ...dailyCheckInStatusColor(device),
      ]">
        <div class="flex justify-between ">
          <div class="justify-between block w-full sm:flex md:block">
            <dt class="text-sm truncate lg:text-xs 2xl:text-sm">{{ $t("device.general.dailyCheckIn") }}</dt>
            <dd class="text-sm font-medium lg:font-semibold lg:text-xs 2xl:text-sm"> {{ dailyCheckInStatusText(device, $t) }} </dd>
          </div>
          <div class="items-center flex-shrink-0 hidden md:flex">
            <CalendarIcon :class="['h-8', 'w-8', 'rounded-full', ...dailyCheckInStatusColor(device), 'px-1']" aria-hidden="true" />
          </div>
        </div>
      </div>
    </dl>

  </div>
</template>

<script>
import {
  braceletPresenceStatusColor,
  stateColor,
  dailyCheckInStatusColor,

} from "@/lib/statusColors";
import { hasAlert, getMostCriticalAlert, hasWifiConnection, getIconOf, getHighestAlert } from "@/lib/alarms";
import { CalendarIcon, WifiIcon, DeviceMobileIcon, BellIcon } from "@heroicons/vue/outline";
import { BraceletIcon } from "@/components/icons";
import { computed } from "vue";
import { useStore } from "vuex";
import { BatteryIcon } from "./icons";
import { braceletPresenceStatusText, wifiStatusText, dailyCheckInStatusText, powerSupplyStatusText, getOnlineStatus } from "@/lib/statusTexts";
import AlarmTooltip from "@/components/tooltips/AlarmTooltip.vue";

export default {
  name: "DeviceStateSummary",
  methods: {
    braceletPresenceStatusText,
    powerSupplyStatusText,
    stateColor,
    dailyCheckInStatusColor,
    dailyCheckInStatusText,
    braceletPresenceStatusColor,
    hasAlert,
    getMostCriticalAlert,
    getHighestAlert,
    hasWifiConnection,
    wifiStatusText,
    getIconOf,
    getOnlineStatus,
  },
  components: {
    CalendarIcon,
    BraceletIcon,
    BatteryIcon,
    WifiIcon,
    BellIcon,
    DeviceMobileIcon,
    AlarmTooltip
  },

  setup() {
    const store = useStore();
    const device = computed(() => store.state.device.current);

    const hasNetworkAlert = computed(() => hasAlert('network', device.value))
    const hasPowerAlert = computed(() => hasAlert('power', device.value))
    const hasAccessoryAlert = computed(() => hasAlert('accessories', device.value))
    const hasWifiAlert = computed(() => hasAlert("wifi", device.value))
    const isConnectedWifi = computed(() => hasWifiConnection(device.value))

    const hasArcConfigured = computed(() => {
      return device.value.configV2.alarm_app.destinations.some(destination => destination.flow === 'scaip');
    });


    const getDeviceId = (device) => {
      return [... new Set(device.configV2.alarm_app.destinations.filter(destination => destination.scaip).map(destination => destination.scaip.controller_id))].join(", ")
    };

    const getConnectionStatus = (device) => {
      let ret;
      if (device.telemetry && device.telemetry.modem) {
        ret = device.telemetry.modem.operator + "(" + device.telemetry.modem.technology + ")";
      }
      return ret;
    };

    const snakeToCamel = (str) =>
      str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));


    return {
      device,
      snakeToCamel,
      getDeviceId,
      getConnectionStatus,
      hasNetworkAlert,
      hasPowerAlert,
      hasAccessoryAlert,
      isConnectedWifi,
      hasWifiAlert,
      hasArcConfigured,
    };
  },
};
</script>
<style>
.fullHeightNetwork:not(:has(#accordion-open-body-network.hidden)),
.fullHeightPower:not(:has(#accordion-open-body-power.hidden)),
.fullHeightAccess:not(:has(#accordion-open-body-accessory.hidden)),
.fullHeightWifi:not(:has(#accordion-open-body-wifi.hidden)) {
  height: unset;
  min-height: 100% !important;
}
</style>
