import i18n from "@/i18n";

const ALARMLEVELS = ["unknown", "not_applicable", "normal", "warning", "minor", "major", "critical"];

const mapStructure = (device) => {
  if (!device) return {};
  return {
    network: {
      modem_link: device.stateAlarms.device_reported_alarms?.network?.modem_link,
      modem_connection_quality: device.stateAlarms.device_reported_alarms?.network?.modem_connection_quality,
      network_cloud_reported: device.stateAlarms.cloud_reported_alarms?.network?.device_presence,
      network_arc_reported: device.stateAlarms.device_reported_alarms?.network?.arc_connection,
    },
    power: {
      power_supply: device.stateAlarms.device_reported_alarms?.power?.power_supply,
      battery_fault: device.stateAlarms.device_reported_alarms?.power?.battery_fault,
    },
    wifi: {
      wifi_link: device.stateAlarms.device_reported_alarms?.network?.wifi_link,
      wifi_connection_quality: device.stateAlarms.device_reported_alarms?.network?.wifi_connection_quality,
    },
    accessories: {
      bracelet_presence: device.stateAlarms.device_reported_alarms?.accessories?.bracelet_presence,
      bracelet_battery: device.stateAlarms.device_reported_alarms?.accessories?.bracelet_battery,
    },
  };
};

/*
 * Return most critical  alert level of 'alert'
 */
const getAlertLevel = (alert, device) => {
  return ALARMLEVELS[getCriticalIndex(alert, device)];
};

const getMostCriticalAlert = (alert, device) => {
  const alarmSummary = mapStructure(device);
  const severity = ALARMLEVELS[getCriticalIndex(alert, device)];
  const severeAlert = Object.keys(alarmSummary[alert]).find((key) => alarmSummary[alert][key] == severity);

  return (
    "<span class='w-6 h-6 mr-2 font-medium lg:w-5 lg:h-5 2xl:w-6 2xl:h-6'>" +
    getIcon(severity) +
    "</span>" +
    i18n.global.t("alarms." + severeAlert)
  );
};

const getIconOf = (alert, alarm, device) => {
  const alarmSummary = mapStructure(device);
  return getIcon(alarmSummary[alert][alarm]);
};

const getIcon = (severity) => {
  if (severity == "normal") {
    return '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#2eb82e" class="size-6">  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /></svg>';
  }
  if (severity == "warning") {
    return '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#FFA500" class="size-6">  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" /></svg>';
  }
  if (severity == "minor") {
    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFA500" class="size-6"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" /></svg>';
  }
  if (severity == "major") {
    return '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#ff3333" class="size-6">  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" /></svg>';
  }
  if (severity == "critical") {
    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#cc2323"  class="size-6">  <path fill-rule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" /></svg>';
  }

  return '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" /></svg>';
};

const getCriticalIndex = (alert, device) => {
  const alarmSummary = mapStructure(device);

  let highestAlertIndex = 0;

  if (device && alarmSummary && alarmSummary[alert]) {
    for (const networkAlert in alarmSummary[alert]) {
      let findIndex = ALARMLEVELS.findIndex((level) => level == alarmSummary[alert][networkAlert]);
      if (findIndex > highestAlertIndex) highestAlertIndex = findIndex;
    }
  }

  return highestAlertIndex;
};

const hasAlert = (alert, device) => {
  const severity = getAlertLevel(alert, device);
  // unknown, not_applicable, normal is not showing alert.
  if (severity == ALARMLEVELS[0] || severity == ALARMLEVELS[1] || severity == ALARMLEVELS[2]) {
    return false;
  }
  return true;
};

const hasWifiConnection = (device) => {
  if (!device) return false;

  if (device && device.configV2 && device.configV2.system.wifi.networks) {
    let foundWifi = device.configV2.system.wifi.networks.filter(
      (network) => network.ssid != "" && network.pre_shared_key != ""
    );
    if (foundWifi && foundWifi.length > 0) return true;
  }
  return false;
};

const getHighestAlert = (type, device) => {
  const alarmSummary = mapStructure(device);
  const severity = ALARMLEVELS[getCriticalIndex(type, device)];
  const alert = Object.keys(alarmSummary[type]).find((key) => alarmSummary[type][key] == severity);

  return alert;
};

const getHighestDescription = (type, device) => {
  const alarmSummary = mapStructure(device);
  const severity = ALARMLEVELS[getCriticalIndex(type, device)];
  const alert = Object.keys(alarmSummary[type]).find((key) => alarmSummary[type][key] == severity);

  return getDescription(type, alert, device, "description");
};

const getLevel = (type, alert, device) => {
  const alarmSummary = mapStructure(device);
  const severity = alarmSummary[type][alert];

  return i18n.global.t(`alarms.levels.${severity}`);
};

const getDescription = (type, alert, device, level) => {
  const alarmSummary = mapStructure(device);
  const severity = alarmSummary[type][alert];

  return i18n.global.t(`alarms.${type}.${alert}.${severity}.${level}`);
};

const isDeviceOnline = (device) => {
  if (!device) return false;

  const alarmSummary = mapStructure(device);
  if (alarmSummary.network.network_cloud_reported != "normal") return false;
  return true;
};

export {
  ALARMLEVELS,
  getAlertLevel,
  hasAlert,
  getMostCriticalAlert,
  hasWifiConnection,
  mapStructure,
  getIconOf,
  getIcon,
  getDescription,
  getLevel,
  getHighestAlert,
  getHighestDescription,
  isDeviceOnline,
};
