import {
  queryDevice,
  queryDevices,
  queryNetworkScans,
  updateOwner,
  createConfigTemplate,
  updateConfigTemplate,
  deleteConfigTemplate,
  queryAggregations,
  updateConfigV2,
  queryConfigTemplates,
} from "@/api/device";

const state = () => ({
  all: new Map(),
  current: {},
  loading: false,
  loadingDevices: false,
  hasNextPage: false,
  nextPageToken: "",
  stateSummaryAggregation: {},
  alarmAggregations: {},
});

const actions = {
  async getDevices({ commit, state, rootState }, forceReload) {
    if (forceReload) {
      commit("setHasNextPage", false);
      commit("setNextPageToken", null);
      commit("setDevices", new Map());
    }

    commit("setLoadingDevices", true);

    let hasNextPage = state.hasNextPage;
    let nextPageToken = state.nextPageToken ? state.nextPageToken : null;
    let queryData = rootState.searchQueryData;

    const result = await queryDevices(nextPageToken, queryData, hasNextPage ? 20 : 50);

    hasNextPage = result.hasNextPage;
    nextPageToken = result.nextPageToken;

    let devices = [];
    if (state.all && state.all.size > 0) {
      devices = Array.from(state.all.values()).concat(result.devices);
    }

    commit("setHasNextPage", hasNextPage);
    commit("setNextPageToken", nextPageToken);
    commit("setDevices", devices.length > 0 ? devices : result.devices);
    commit("setLoadingDevices", false);
  },

  async getAggregations({ commit, rootState }) {
    commit("setLoading", true);
    const queryData = rootState.searchQueryData;
    const aggregation = await queryAggregations(queryData);

    if (aggregation && aggregation.deviceStateAggregations) {
      commit("setStateSummaryAggregation", aggregation.deviceStateAggregations);
    }
    if (aggregation && aggregation.deviceAlarmsAggregations) {
      commit("setAlarmAggregations", aggregation.deviceAlarmsAggregations);
    }
    commit("setLoading", false);
  },

  async getDevice({ commit }, serialNumber) {
    commit("setLoadingDevices", true);

    const device = await queryDevice(serialNumber);

    commit("setDevice", device);
    commit("setLoadingDevices", false);
  },

  async reloadDevice({ commit }, serialNumber) {
    commit("setLoadingDevices", true);
    const device = await queryDevice(serialNumber);
    commit("setDevice", device);
    commit("setLoadingDevices", false);
    return true;
  },

  async reloadNetworkScans({ commit }, deviceId) {
    const networkScans = await queryNetworkScans(deviceId);
    commit("updateNetworkScans", networkScans);
  },

  async queryConfigTemplates({ commit }) {
    const configTemplates = await queryConfigTemplates();
    commit("setConfigTemplates", configTemplates);
  },

  async createConfigTemplate({ commit }, payload) {
    commit("setLoading", true);
    const ret = await createConfigTemplate(payload.template);
    commit("setLoading", false);
    if (ret && ret.template.create.ok) return true;
    return false;
  },

  async updateConfigTemplate({ commit }, payload) {
    commit("setLoading", true);
    const ret = await updateConfigTemplate(payload.template);
    commit("setLoading", false);
    if (ret && ret.template.update.ok) return true;
    return false;
  },

  async deleteConfigTemplate({ commit }, payload) {
    commit("setLoading", true);
    const ret = await deleteConfigTemplate(payload.templateId);
    commit("setLoading", false);
    if (ret && ret.template.delete.ok) return true;
    return false;
  },

  async updateOwner({ commit }, payload) {
    const { deviceId, tenant } = payload;
    await updateOwner([deviceId], tenant.id);
    commit("setOwner", tenant);
  },

  async batchUpdateOwner({ commit }, payload) {
    const { deviceIds, tenant } = payload;
    await updateOwner(deviceIds, tenant.id);
    commit("batchSetOwner", payload);
  },

  async updateConfig({ commit, state }, data) {
    commit("setLoading", true);
    commit("setConfig", data);

    const ret = await updateConfigV2(state.current.id ? state.current.id : data.id, data.data);
    commit("setLoading", false);

    if (ret && ret.device.config.updateDeviceConfigV2) return true;
    return false;
  },

  async updateMultipleConfigs({ commit, dispatch }, data) {
    commit("setLoading", true);

    if (data && data.length > 0) {
      let ret = true;
      for (let d of data) {
        let ret = await updateConfigV2(d.id, d.data);
        if (!ret || !ret.device.config.updateDeviceConfigV2) ret = false;
      }
      await dispatch("getDevices", true);
      commit("setLoading", false);
      return ret;
    } else {
      commit("setLoading", false);
      return false;
    }
  },

  setLoading({ commit }, data) {
    commit("setLoading", data);
  },
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },

  setLoadingDevices(state, loading) {
    state.loadingDevices = loading;
  },

  setHasNextPage(state, nextPage) {
    state.hasNextPage = nextPage;
  },

  setNextPageToken(state, pageToken) {
    state.nextPageToken = pageToken;
  },

  setDevices(state, devices) {
    state.all.clear();
    devices.forEach((device) => {
      state.all.set(device.id, device);
    });
  },

  setStateSummaryAggregation(state, aggregation) {
    state.stateSummaryAggregation = aggregation;
  },

  setAlarmAggregations(state, aggregation) {
    state.alarmAggregations = aggregation;
  },

  setDevice(state, device) {
    state.current = { ...device };
  },

  updateNetworkScans(state, networkScans) {
    state.current.networkScans = networkScans;
  },

  setConfigTemplates(state, configTemplates) {
    state.current.configTemplates = configTemplates;
  },

  setConfig(state, data) {
    state.current.configV2 = data.data;
  },

  setOwner(state, { tenant }) {
    state.current.owner = tenant;
  },

  batchSetOwner(state, { deviceIds, tenant }) {
    deviceIds.forEach((deviceId) => {
      const device = state.all.get(deviceId);
      device.owner = tenant;
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
