<template>

  <tr v-for="row in 5" :key="row" class="animate-pulse">
    <td v-if="isSuperAdmin" data-interactive class="w-12 px-4 py-2">
      <div class="w-4 h-4 bg-gray-300 rounded"></div>
    </td>

    <!-- deviceConnectivityStatus -->
    <td class="w-5 px-1 py-2">
      <div class="h-2.5 w-2.5 bg-gray-300 rounded-full"></div>
    </td>

    <!-- serialNumber -->
    <td class="px-1 py-2">
      <div class="w-full h-4 bg-gray-300 rounded"></div>
    </td>

    <!-- tenant -->
    <td class="px-1 py-2">
      <div class="w-full h-4 bg-gray-300 rounded"></div>
    </td>

    <!-- id -->
    <td class="px-1 py-2">
      <div class="w-full h-4 bg-gray-300 rounded"></div>
    </td>

    <!-- braceletColumn -->
    <td class="px-1 py-2">
      <div class="w-full h-4 bg-gray-300 rounded"></div>
    </td>

    <td class="flex flex-row w-fit ">
      <div class="w-6 h-6 bg-gray-300 rounded-full"></div>

      <div class="w-6 h-6 bg-gray-300 rounded-full"></div>

      <div class="w-6 h-6 bg-gray-300 rounded-full"></div>

      <div class="w-6 h-6 bg-gray-300 rounded-full"></div>

      <div class="w-6 h-6 bg-gray-300 rounded-full"></div>
    </td>

    <td class="pr-4">
      <div class="w-full h-4 bg-gray-300 rounded"></div>
    </td>
  </tr>

</template>
<script>
export default {
  name: "DeviceListPlaceholder",
  props: {
    isSuperAdmin: { required: true },
  },
};
</script>
