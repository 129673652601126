<template>
    <authenticator :form-fields="formFields" class="h-screen " :login-mechanisms="['email']" :hide-sign-up="true">
        <template v-slot:header>
            <div style="padding: var(--amplify-space-large); text-align: center">
                <img class="h-20 amplify-image" src="../assets/logo_black.png" alt="CARU" />
            </div>
        </template>
    </authenticator>

</template>
<script>

import { Authenticator, useAuthenticator } from "@aws-amplify/ui-vue";
import { useRouter } from "vue-router";
import { watch, ref } from "vue";

export default {
    components: {
        Authenticator,
    },
    setup() {
        const auth = useAuthenticator();
        const router = useRouter();
        const hasStateChanged = ref(false);

        watch(auth, () => {
            if (auth && auth.authStatus == 'authenticated' && !hasStateChanged.value) {
                hasStateChanged.value = true;
                router.push({ name: "devices", replace: true })
            }
        });
    }
}

</script>
