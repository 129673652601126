import Login from "./views/LoginView.vue";
import Devices from "./views/DeviceList.vue";
import Device from "./views/DeviceDetails.vue";
import Users from "./views/UserList.vue";
import ConfigTemplates from "./views/ConfigTemplates.vue";
import UserTenantManagement from "./views/UserTenantManagement.vue";
import NotFound from "./views/NotFound.vue";
import DeviceTabGeneral from "@/components/DeviceTabGeneral";
import { createRouter, createWebHistory } from "vue-router";
import { AuthFilter } from "@/lib/auth";
import Store from "@/store";
import DeviceTabNetwork from "@/components/DeviceTabNetwork";
import UserManagement from "@/components/UserManagement";
import TenantManagement from "@/components/TenantManagement";

const routes = [
  {
    path: "/login",
    component: Login,
    meta: { title: "Login" },
    name: "login",
  },

  {
    path: "/",
    component: Devices,
    meta: { title: "Devices" },
    name: "devices",
  },
  {
    path: "/device/:serialNumber",
    component: Device,
    meta: { title: "Device" },
    beforeEnter: async (to, from) => {
      if (to.params.serialNumber !== from.params.serialNumber) {
        Store.dispatch("device/getAggregations");
        await Store.dispatch("device/getDevice", to.params.serialNumber);
      }
    },
    children: [
      {
        path: "",
        component: DeviceTabGeneral,
        name: "device",
      },

      {
        path: "network",
        component: DeviceTabNetwork,
        name: "deviceTabNetwork",
      },
    ],
  },
  {
    path: "/templates",
    component: ConfigTemplates,
    meta: { title: "Config Templates" },
    name: "config-templates",
  },
  {
    path: "/usermanagement",
    component: UserTenantManagement,
    meta: { title: "User Management" },

    children: [
      {
        path: "",
        component: UserManagement,
        name: "userManagement",
      },

      {
        path: "tenants",
        component: TenantManagement,
        name: "tenantManagement",
      },
    ],
  },
  { path: "/users", component: Users, meta: { title: "Users" }, name: "users" },
  { path: "/:path(.*)", component: NotFound, meta: { title: "Not Found" } },
  // Isolated components with mock data for development
  // { path: "/dev/bracelets", component: BraceletsEditor },
  // { path: "/dev/alertdestination", component: AlertDestinationEditor },
  // {path: '/dev/notification', component: NotificationEditor},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(AuthFilter);

export default router;
