<template>
  <td class="w-5 px-2 py-2" ref="deviceListItems">
    <div ref="onlineTooltipTrigger" :class="[isDeviceOnline(device) ? 'bg-green-500' : 'bg-red-500', 'w-2.5', 'h-2.5', 'rounded-full', 'group ']" aria-hidden="true" />
    <div v-show="isDeviceOnline(device)" ref="onlineTooltipTarget" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
      Online
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
  </td>

  <td class="px-1 py-2 font-medium text-left w-28" ref="serialNumber">
    {{ device.serialNumber }}
  </td>

  <td class="px-1 py-2 font-medium text-left w-28">
    {{ device.owner.name }}
  </td>

  <td class="px-1 py-2 font-medium text-left w-28">
    {{ getDeviceControllerID(device) }}
  </td>

  <td class="w-full px-1 py-2 font-light break-words sm:break-keep sm:w-fit">
    {{device.configV2.accessories.bracelets.map(bracelet => bracelet.serial_number).join(", ")}}
  </td>

  <td ref="calleeTooltipTrigger" v-if="isSuperAdmin && hasCallee(device)" class="w-full px-1 py-2 font-light break-words sm:break-keep sm:w-fit">
    {{ getFirstCallee(device) }}

    <div ref="calleeTooltipTarget" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
      <p v-html="getAllCallees(device)"></p>
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
  </td>
  <td v-else class="w-full px-1 py-2 font-light break-words sm:break-keep sm:w-fit"></td>


  <td class="px-1 py-2">
    <div class="flex flex-row justify-end w-full gap-2 pr-5">
      <div :id="'networkTooltipItem' + device.serialNumber" @mouseover="showTooltip('network', true)" @mouseleave="showTooltip('network', false)">
        <DeviceMobileIcon :class="['h-6 w-6 rounded-full', ...stateColor(device, 'network'), 'px-1']" aria-hidden="true" ref="networkTooltipItem" />
        <AlarmTooltip v-if="tooltipVisible.network" :targetId="'networkTooltipItem' + device.serialNumber" type="network" :alert="getHighestAlert('network', device)" :device="device" :fallback="!getAlert('network') ? getOnlineStatus(device) : ''" />
      </div>

      <div>
        <div :id="'wifiTooltipTrigger' + device.serialNumber" @mouseover="showTooltip('wifi', true)" @mouseleave="showTooltip('wifi', false)">
          <WifiIcon v-if="isConnectedWifi" :class="['h-6 w-6 rounded-full', ...stateColor(device, 'wifi'), 'px-1']" aria-hidden="true" />
          <div v-else class='w-6 h-6 px-1 text-gray-800 bg-gray-100 rounded-full'></div>
        </div>
        <AlarmTooltip v-if="tooltipVisible.wifi" :targetId="'wifiTooltipTrigger' + device.serialNumber" type="wifi" :alert="getHighestAlert('wifi', device)" :device="device" :fallback="!getAlert('wifi') ? getWifiStatus() : ''" />
      </div>

      <div :id="'powerSupplyStatusTooltipTrigger' + device.serialNumber" @mouseover="showTooltip('power', true)" @mouseleave="showTooltip('power', false)">
        <BatteryIcon :class="['h-6 w-6 rounded-full', ...stateColor(device, 'power'), 'px-1']" aria-hidden="true" />
        <AlarmTooltip v-if="tooltipVisible.power" :targetId="'powerSupplyStatusTooltipTrigger' + device.serialNumber" type="power" :alert="getHighestAlert('power', device)" :device="device" :fallback="!getAlert('power') ? getPowerStatus() : ''" />
      </div>

      <div :id="'braceletStatusTooltipTrigger' + device.serialNumber" @mouseover="showTooltip('accessories', true)" @mouseleave="showTooltip('accessories', false)">
        <BraceletIcon :class="['h-6 w-6 rounded-full', ...stateColor(device, 'accessories'), 'px-1']" aria-hidden="true" />
        <AlarmTooltip v-if="tooltipVisible.accessories" :targetId="'braceletStatusTooltipTrigger' + device.serialNumber" type="accessories" :alert="getHighestAlert('accessories', device)" :device="device" :fallback="!getAlert('accessories') ? getBraceletStatus() : ''" />
      </div>

      <div>
        <CalendarIcon ref="dailyCheckInStatusTooltipTrigger" :class="['h-6 w-6 rounded-full', ...dailyCheckInStatusColor(device), 'px-1']" aria-hidden="true" />
        <div ref="dailyCheckInStatusTooltipTarget" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
          {{ getDailyCheckInStatusText() }}
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>
    </div>
  </td>

  <td class="w-16 px-1 py-2 pr-4 text-sm font-light">
    {{ device.firmware ? device.firmware.version : "" }}
  </td>
</template>

<script>
import {
  dailyCheckInStatusColor,
  stateColor,
} from "@/lib/statusColors";
import { DeviceMobileIcon, CalendarIcon, WifiIcon } from "@heroicons/vue/outline";
import { BatteryIcon } from "@/components/icons";
import { Tooltip } from "flowbite";
import { onMounted, ref, reactive } from "vue";
import { computed } from "vue";
import { isDeviceOnline, hasWifiConnection, hasAlert, getHighestDescription, getHighestAlert } from "@/lib/alarms";
import { useI18n } from "vue-i18n";
import { getOnlineStatus, wifiStatusText, powerSupplyStatusText, dailyCheckInStatusText, braceletPresenceStatusText } from "@/lib/statusTexts";
import { BraceletIcon } from "@/components/icons";
import AlarmTooltip from "@/components/tooltips/AlarmTooltip.vue";

export default {
  methods: {
    isDeviceOnline,
    getOnlineStatus,
    hasWifiConnection,
    getHighestDescription,
    getHighestAlert
  },
  components: {
    DeviceMobileIcon,
    CalendarIcon,
    BatteryIcon,
    WifiIcon,
    BraceletIcon,
    AlarmTooltip
  },
  name: "DeviceListItem",
  props: {
    device: { required: true },
    isSuperAdmin: { required: true },
  },
  setup(props) {
    const { t } = useI18n();

    const isConnectedWifi = computed(() => hasWifiConnection(props.device))

    const onlineTooltipTrigger = ref(null);
    const onlineTooltipTarget = ref(null);

    const calleeTooltipTrigger = ref(null);
    const calleeTooltipTarget = ref(null);

    const tooltipVisible = reactive({
      network: false,
      wifi: false,
      power: false,
      accessories: false,
      dailyCheckIn: false,

    });

    onMounted(() => {
      const options = {
        placement: 'bottom',
        triggerType: 'hover',
      }
      new Tooltip(onlineTooltipTarget.value, onlineTooltipTrigger.value, options);

      if (props.device && props.isSuperAdmin) {
        new Tooltip(calleeTooltipTarget.value, calleeTooltipTrigger.value, options);
      }
    })

    const showTooltip = (type, visible) => {
      tooltipVisible[type] = visible;
    };

    const getDeviceControllerID = (device) => {
      return [... new Set(device.configV2.alarm_app.destinations.filter(destination => destination.scaip).map(destination => destination.scaip.controller_id))].join(", ")
    }

    const getFirstCallee = (device) => {
      let arc = device.configV2.alarm_app.destinations.filter(destination => destination.call);
      return arc.length > 0 ? arc[0].call.callee : "";
    }

    const hasCallee = (device) => {
      if (getFirstCallee(device) == "") return false;
      return true;
    }

    const getAllCallees = (device) => {
      let arcs = device.configV2.alarm_app.destinations.filter(destination => destination.call);
      return arcs.map(arc => arc.call.callee + " Versuche: " + arc.attempts).join("<br>");
    }

    const getAlert = (alert) => {
      if (hasAlert(alert, props.device)) {
        return getHighestDescription(alert, props.device)
      }
      return false;
    }

    const getWifiStatus = () => {
      if (!isConnectedWifi.value) return t("alarms.wifiNotConnected")
      return wifiStatusText(props.device, t)
    }

    const getPowerStatus = () => {
      const status = powerSupplyStatusText(props.device, t)
      if (status == "-") return t("device.tooltip.powerSupplyStatus");
      return status;
    }


    const getBraceletStatus = () => {
      const status = braceletPresenceStatusText(props.device, t);
      if (status == "-") return t("device.tooltip.braceletPresenceStatus");
      return status;
    }

    const getDailyCheckInStatusText = () => {
      const status = dailyCheckInStatusText(props.device, t)
      if (status == "-") return t("device.tooltip.dailyCheckIn")
      return status;
    }

    return {
      onlineTooltipTrigger,
      onlineTooltipTarget,
      dailyCheckInStatusColor,
      stateColor,
      isConnectedWifi,
      getDeviceControllerID,
      getWifiStatus,
      getPowerStatus,
      getDailyCheckInStatusText,
      getBraceletStatus,
      getAlert,
      tooltipVisible,
      showTooltip,
      getFirstCallee,
      getAllCallees,
      hasCallee,
      calleeTooltipTrigger,
      calleeTooltipTarget

    };
  },
};
</script>
