<template>






    <TransitionRoot as="template" :show="open">
        <Dialog as="div" static class="fixed inset-0 z-50 overflow-y-auto" @close="selectedPreviewDevice = ''; $emit('cancel')" :open="open">
            <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0 ">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
                </TransitionChild>

                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">

                    <div v-if="loaded" class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                        <div class="sm:flex sm:items-start">

                            <div class="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left">
                                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                                    {{ $t("device.action.applyConfigTemplate") }}
                                </DialogTitle>
                                <div class="w-full mt-8">

                                    <Combobox as="div" v-model="selectedTemplate" class="w-full">
                                        <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900">{{ $t("configTemplates.listPageTitleEdit") }}</ComboboxLabel>
                                        <div class="relative mt-2">
                                            <ComboboxInput class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" @change="query = $event.target.value" :display-value="(template) => template?.name" />
                                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                                                <ChevronDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                                            </ComboboxButton>

                                            <ComboboxOptions v-if="filteredTemplate.length > 0" class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                <ComboboxOption v-for="template in filteredTemplate" :key="template.id" :value="template" as="template" v-slot="{ active, selected }">
                                                    <li :class="['relative cursor-default select-none py-1 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                                        <span :class="['block truncate', selected && 'font-semibold']">
                                                            {{ template.name }}
                                                        </span>

                                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                                            <CheckIcon class="w-5 h-5" aria-hidden="true" />
                                                        </span>
                                                    </li>
                                                </ComboboxOption>
                                            </ComboboxOptions>
                                        </div>
                                    </Combobox>

                                    <div class="flex flex-col mt-4 overflow-y-scroll max-h-96">

                                        <div v-for="(device, index) in devices" :key="device">
                                            <div class="flex text-sm font-medium leading-6 text-gray-900">
                                                <div class="text-gray-600 ">Device:</div>
                                                <div class="ml-2 font-bold">
                                                    {{ device.substring(device.lastIndexOf(":") + 1) }}
                                                </div>
                                                <div class="mx-auto ml-6 cursor-pointer" @click="selectedPreviewDevice = device">
                                                    <EyeOffIcon v-if="selectedPreviewDevice != device" class="w-5 h-5 text-gray-500"></EyeOffIcon>
                                                    <EyeIcon v-else class="w-5 h-5 text-gray-500"></EyeIcon>
                                                </div>
                                            </div>

                                            <div :class="['w-full mt-1 border-gray-200 whitespace-nowrap h-fit', placeholders && 'mb-4']" v-if="placeholders">
                                                <div class="flex min-w-full " v-for="innerPlaceholder in placeholders[index]" :key="innerPlaceholder.id">
                                                    <div class="w-1/4 px-4 py-2 text-gray-500 bg-gray-100 border whitespace-nowrap"> {{ innerPlaceholder.placeholder }} </div>
                                                    <input v-model="innerPlaceholder.value" :placeholder='$t("configTemplates.header.example")' class="w-full px-4 py-2 border-b-2 border-gray-200 whitespace-nowrap focus:shadow-md focus:border-gray-200 focus:border-rounded-lg focus:bg-gray-50 focus:outline-none focus:ring-0" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="w-full mt-8">
                                        <div class="block text-sm font-medium leading-6 text-gray-600"> {{ $t("configTemplates.header.preview") }}:<span class="ml-2 font-bold text-gray-900"> {{ selectedPreviewDevice && selectedPreviewDevice.substring(selectedPreviewDevice.lastIndexOf(":") + 1) }}</span> </div>
                                        <JsonEditorVue class='w-full mt-2 border-gray-200 rounded-lg whitespace-nowrap' v-model="exampleTemplate" v-bind="{ mode: 'text', mainMenuBar: false, readOnly: true }" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-5 sm:mt-6 sm:mx-4 sm:flex sm:flex-row-reverse">
                            <button type="button" :disabled="disabledButton" class="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-400" @click="confirmOpen = true">
                                {{ $t("common.action.apply") }}
                            </button>
                            <button type="button" class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="selectedPreviewDevice = ''; selectedTemplate = {}; $emit('cancel')" ref="cancelButtonRef">
                                {{ $t("common.action.cancel") }}
                            </button>
                        </div>
                    </div>
                    <div v-else class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6 min-h-[250px]">
                        <div class="flex items-center justify-center h-full">
                            <LoadingSpinner>
                            </LoadingSpinner>
                        </div>
                    </div>

                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <SimpleAlert @cancel="confirmOpen = false;" @approve="save" :open="confirmOpen" :title="$t('alert.applyChangesTitle')" :description="$t('alert.applyChangesDescription')" :action="$t('common.action.apply')" type="info"></SimpleAlert>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, Combobox, ComboboxButton, ComboboxInput, ComboboxLabel, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon, EyeIcon, EyeOffIcon } from "@heroicons/vue/outline";
import { ref, computed } from "vue";
import JsonEditorVue from 'json-editor-vue'
import { watch } from "vue";
import SimpleAlert from "@/components/modals/SimpleAlert";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { queryDevice } from "@/api/device";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
    components: {
        Dialog,
        DialogOverlay,
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        LoadingSpinner,
        CheckIcon,
        ChevronDownIcon,
        JsonEditorVue,
        SimpleAlert,
        EyeIcon,
        EyeOffIcon,
    },

    props: ["open", "devices"],
    emits: ["approve", "cancel"],



    setup(props, { emit }) {

        const store = useStore();
        const loaded = ref(false);

        watch(() => props.open, async () => {
            loaded.value = false;
            if (props.open) {
                const isSuperAdmin = await store.getters["auth/isSuperAdmin"];
                if (isSuperAdmin) {
                    await store.dispatch("device/queryConfigTemplates");
                    loaded.value = true;
                }
            }
        })

        const { t } = useI18n();

        const templates = computed(() => store.state.device.current.configTemplates);

        const confirmOpen = ref(false);
        const disabledButton = ref(true);
        const exampleTemplate = ref()
        const placeholders = ref()
        const query = ref('')
        const selectedTemplate = ref({});
        const filteredTemplate = computed(() =>
            query.value === ''
                ? templates.value
                : templates.value.filter((template) => {
                    return template.name.toLowerCase().includes(query.value.toLowerCase())
                })
        )
        const selectedPreviewDevice = ref("")

        const getPlaceholders = () => {
            if (selectedTemplate.value && selectedTemplate.value.template) {
                if (((JSON.stringify(selectedTemplate.value.template).split('$') || []).length - 1) % 2 == 0) {
                    const regex = "\\$([^$]+)\\$";
                    let placeholders = [...new Set([...JSON.stringify(selectedTemplate.value.template).matchAll(regex)].map((placeholder) => placeholder[0]))].map((placeholder) => ({ placeholder: placeholder, value: '' }));
                    placeholders = placeholders.map((item, index) => ({ ...item, id: index + 1 }));
                    return JSON.parse(JSON.stringify(Array(props.devices.length).fill(placeholders)));

                }
                return placeholders.value;
            }
            return "";
        }

        const parseTemplateBody = (placeholderList, templateBody) => {

            let body;
            if (typeof templateBody === "string") {
                body = JSON.parse(templateBody);
            } else {
                body = templateBody;
            }

            let placeholders = placeholderList.value[props.devices.findIndex((device) => device == selectedPreviewDevice.value)]
            if (placeholders) {
                for (const { placeholder, value } of placeholders) {
                    if (placeholder && value != '') {
                        const parsed = JSON.stringify(body).replaceAll(placeholder, value);
                        exampleTemplate.value = JSON.parse(parsed);
                        body = exampleTemplate.value;
                    }
                }
                if (placeholders.filter((y) => y.value != '').length == 0) exampleTemplate.value = JSON.parse(templateBody);
            }

        }

        watch(selectedTemplate, () => {
            placeholders.value = getPlaceholders();
            exampleTemplate.value = selectedTemplate.value.template;
            selectedPreviewDevice.value = props.devices[0];

        })

        watch(placeholders, () => {
            parseTemplateBody(placeholders, selectedTemplate.value.template)

            if (placeholders.value && !placeholders.value.map((elem) => elem).flat().find((item) => item.value == '')) disabledButton.value = false;
        }, { deep: true })

        watch(selectedPreviewDevice, () => {
            if (selectedPreviewDevice.value != "") parseTemplateBody(placeholders, selectedTemplate.value.template)
        })


        const save = async () => {

            confirmOpen.value = false;

            let data = [];
            let index = 0;
            for (let device of props.devices) {
                const deviceData = await queryDevice(device.substring(device.lastIndexOf(":") + 1));

                let fullConfig = JSON.parse(JSON.stringify(deviceData.configV2));
                const currentPlaceholders = placeholders.value[index];

                let templateConfig;
                if (typeof selectedTemplate.value.template === "string") {
                    templateConfig = JSON.parse(selectedTemplate.value.template);
                } else {
                    templateConfig = selectedTemplate.value.template;
                }

                for (const { placeholder, value } of currentPlaceholders) {
                    if (placeholder && value != '') {
                        const parsed = JSON.stringify(templateConfig).replaceAll(placeholder, value);
                        templateConfig = JSON.parse(parsed);
                    }
                }

                for (const templateKey of Object.keys(templateConfig)) {
                    if (Object.keys(fullConfig).includes(templateKey)) {
                        fullConfig = Object.assign(fullConfig, templateConfig)
                    } else {
                        for (const [key, value] of Object.entries(fullConfig)) {
                            if (Object.keys(value).includes(templateKey)) {
                                const tmpConfig = Object.assign(fullConfig[key], templateConfig)
                                fullConfig = Object.assign(fullConfig, tmpConfig);
                            }
                        }
                    }
                }

                data.push({
                    id: device,
                    data: fullConfig,
                })
                index++;
            }

            await store.dispatch("device/updateMultipleConfigs", data);


            store.dispatch("showNotification", {
                title: t("notifications.mutations.success"),
                text: t("notifications.mutations.delivering"),
                type: "info",
            });

            selectedTemplate.value = {};
            query.value = '';

            emit('cancel');
        }

        return {
            templates,
            query,
            selectedTemplate,
            filteredTemplate,
            placeholders,
            exampleTemplate,
            disabledButton,
            confirmOpen,
            selectedPreviewDevice,
            save,
            loaded
        }
    }
};
</script>
