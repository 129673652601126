<template>
    <div ref='alarmTooltip' role="tooltip" class="sm:max-w-[350px] inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
        <div v-if="fallback.length > 0">
            <p>{{ fallback }}</p>
        </div>
        <div v-else>
            <p class="pb-2 font-bold underline">{{ $t(`alarms.${type}.${alert}.title`) }}</p>
            <p class="font-bold ">{{ $t("alarms.headers.description") }}</p>
            <p class="pb-2 text-sm">{{ getDescription(type, alert, device, 'description') }}</p>
            <p class="font-bold ">{{ $t("alarms.headers.urgency") }}: <span class="underline">{{ getLevel(type, alert, device) }}</span></p>
            <p class="pb-2 text-sm">{{ getDescription(type, alert, device, 'urgency') }}</p>
            <p class="font-bold ">{{ $t("alarms.headers.measures") }}</p>
            <p class="pb-2 text-sm">{{ getDescription(type, alert, device, 'measures') }}</p>
            <p class="font-bold ">{{ $t("alarms.headers.updatedat") }}</p>
            <p class="pb-2 text-sm">{{ getDate(device) }}</p>
            <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from 'flowbite';
import { getDescription, getLevel } from "@/lib/alarms";
import moment from "moment";

export default {
    props: {
        targetId: String,
        type: String, // network, power, wifi, etc
        alert: String, // modem_connection_quality, wifi_connection_quality etc
        device: Object,
        fallback: {
            type: String,
            default: '',
        },
    },
    mounted() {
        const targetElement = document.getElementById(this.targetId);
        if (targetElement && this.$refs.alarmTooltip) {
            const options = {
                placement: 'bottom',
                triggerType: 'hover',
            }
            new Tooltip(this.$refs.alarmTooltip, targetElement, options);
        }
    },

    setup() {

        const getDate = (device) => {
            moment.locale("de")
            return moment(device.stateAlarms.device_reported_alarms_updated).format('LLL')
        }

        return {
            getDescription,
            getLevel,
            getDate,
            moment
        }
    }
};
</script>
