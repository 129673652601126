import { getAlertLevel } from "./alarms";

const getOnlineStatus = (device) => {
  if (!device) return "Offline";

  const severity = getAlertLevel("network", device);
  if (severity === "normal") return "Online";
  return "Offline";
};

const wifiStatusText = (device, t) => {
  const severity = getAlertLevel("wifi", device);
  if (severity == "normal") return t("stateSummary.powerSupply.connected");

  return "-";
};

const powerSupplyStatusText = (device, t) => {
  if (device.stateAlarms && device.stateAlarms.device_state.power.power_supply === "disconnected") {
    return t("stateSummary.powerSupply.disconnected");
  }
  if (device.stateAlarms && device.stateAlarms.device_state.power.power_supply === "connected") {
    return t("stateSummary.powerSupply.connected");
  }

  return "-";
};

const braceletPresenceStatusText = (device, t) => {
  const severity = getAlertLevel("accessories", device);

  if (severity === "normal") return t("stateSummary.braceletPresence.inRange");
  if (severity === "unknown") return t("stateSummary.braceletPresence.unknown");

  return "-";
};

const dailyCheckInStatusText = (device, t) => {
  if (device.stateSummary.cloudReportedDevicePresence != "online") {
    return "-";
  }
  if (device.stateSummary.dailyCheckIn === "active") {
    return t("stateSummary.dailyCheckIn.active");
  }
  if (device.stateSummary.dailyCheckIn === "paused_by_device") {
    return t("stateSummary.dailyCheckIn.pausedByDevice");
  }
  if (device.stateSummary.dailyCheckIn === "paused_by_platform") {
    return t("stateSummary.dailyCheckIn.pausedByPlatform");
  }
  if (device.stateSummary.dailyCheckIn === "missing") {
    return t("stateSummary.dailyCheckIn.missing");
  }
  return "-";
};

export { dailyCheckInStatusText, wifiStatusText, getOnlineStatus, powerSupplyStatusText, braceletPresenceStatusText };
